<template>
  <div>
    <h2 class="text-center my-8">-: Components testing page :-</h2>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-50">
        <TextEditor />
      </div>
      <div class="md-layout-item">
        <MultipleChoiseAnswer />
      </div>

      <div class="md-layout-item md-size-100 md-layout md-gutter">
        <div class="md-layout-item">
          <UploadComponent
            :switcher="false"
            icon-class="flaticon-photo-camera"
          />
        </div>
        <div class="md-layout-item">
          <UploadComponent icon-class="flaticon-volume" />
        </div>
        <div class="md-layout-item">
          <!-- <UploadComponent icon-class="flaticon-video-camera" /> -->
          <!-- <MaterialCard /> -->
        </div>
      </div>
      <div class="md-layout-item md-size-100 md-layout md-gutter">
        <div class="md-layout-item md-size-100 mt-4">
          <AccordionComponent />
        </div>
        
        <div class="md-layout-item md-size-100 mt-4">
          <PracticeList />
        </div>
        
        <div class="md-layout-item md-size-100 mt-4">
           <PageTopNavigation />
        </div>

      </div>
      <div class="md-layout-item md-size-100 md-layout md-gutter">
        <div class="md-layout-item md-size-100 mt-4">
          <BarChart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextEditor from "@/components/atom/form/TextEditor";
import MultipleChoiseAnswer from "@/components/molecule/question/MultipleChoiseAnswer";
import UploadComponent from "@/components/molecule/question/UploadComponent";
import AccordionComponent from "@/components/molecule/AccordionComponent";
import PracticeList from '@/components/molecule/practice/PracticeList';
import PageTopNavigation from '@/components/atom/PageTopNavigation'
import BarChart from '@/components/atom/BarChart'
// import MaterialCard from '@/components/molecule/material/MaterialCard'
export default {
  components: {
    BarChart,
    TextEditor,
    MultipleChoiseAnswer,
    UploadComponent,
    AccordionComponent,
    PracticeList,
    PageTopNavigation,
    // MaterialCard
  },
};
</script>

<style></style>
