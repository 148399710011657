<template>
  <div
    style="height: 220px; min-width: 18rem"
    class="py-6 px-3 flex flex-col rounded outline-gray-400"
    :class="[switcher ? 'justify-between': 'justify-center']"
  >
    <div v-if="switcher" class="flex justify-center">
      <div class="border border-solid border-gray-400 py-2 px-4 pointer"
        :class="activeRecord" @click="record = true">
        Record
      </div>
      <div
        class="border border-solid border-gray-400 py-2 px-4 pointer"
        :class="activeBrowse"
        @click="onFileBrowse"
      >Browse</div>
      <input ref="file" type="file" class="hidden" @change="getFileContent">
    </div>
    <div class="flex justify-center" v-if="record">
      <div class="py-5 px-10 inline-flex align-center rounded bg-victoria text-white pointer" @click="onPlacedComponent(type)">
        <i :class="iconClass" class="text-5xl"></i>
        <div class="pl-3 text-left">
          <p class="text-lg text-capitalize">{{ labelSplit[0] }}</p>
          <p class="text-lg text-capitalize">{{ labelSplit[1] }}</p>
        </div>
      </div>
    </div>
    
    <div v-else>
      <div class="align-center w-full">
        <template v-if="type == 'audio'">
          <audio-player src="/demo/example.mp3"/>
        </template>
        
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      record: true,
      fileName: ""
      }
    },
    props: {
        iconClass: {
            type: String,
            required: true
        },
        switcher: {
            type: Boolean,
            default: () => true
        },
        label: {
          type: String,
          default: () => 'Start Recording'
        },
        type: {
          type: String,
          required: true,
          validator: function(value) {
            return ["image", "audio", "video"].indexOf(value) !== -1;
          },
        }
    },
    computed: {
      activeRecord() {
        return this.record ? 'bg-victoria text-white' : ''
      },
      activeBrowse() {
        return !this.record ? 'bg-victoria text-white' : ''
      },
      labelSplit() {
        return this.label.split(' ')
      }
    },
    methods: {
      getFileContent(e) {
        this.fileName = e.target.files[0].name;
        this.$emit('input', e.target.files[0]);
      },
      onFileBrowse() {
        this.record = false;
        this.$refs.file.click();
      },
      onPlacedComponent(type) {
        console.log(type, '==@@==');
      }
    }
};
</script>

<style></style>
